import {required, maxLength, minLength} from 'vuelidate/lib/validators'

export default {
  brand: {
    brand: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(25)
    }
  }
}
