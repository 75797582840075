<template>
  <div>
    <md-button @click="showDialog = true">Crear nuevo</md-button>
    <md-dialog :md-active.sync="showDialog">
    <md-dialog-title>Nueva marca</md-dialog-title>
    <div class="md-layout md-alignment-center margin-fix">
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Nombre de marca</label>
          <md-input v-model="$v.brand.brand.$model" />
        </md-field>
        <div v-if="$v.brand.brand.$dirty">
          <span class="md-helper-text" v-if="!$v.brand.brand.required">Campo requerido</span>
          <span class="md-helper-text" v-if="!$v.brand.brand.minLength">Minimo 2 caracteres</span>
          <span class="md-helper-text" v-if="!$v.brand.brand.maxLength">Maximo de caracteres</span>
        </div>
      </div>
    </div>
    <md-dialog-actions class="margin-fix">
      <md-button class="md-primary" @click="onCancel">Cerrar</md-button>
      <md-button class="md-primary" @click="onConfirm">Crear</md-button>
    </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import validations from '@/validations/brands'
export default {
  name: 'Create',
  data() {
    return {
      showDialog: false,
      brand: {
        brand: null
      }
    }
  },
  mounted() {
    Object.assign(this.$data, this.$options.data.apply(this))
  },
  validations: validations,
  methods: {
    onConfirm () {
      if (!this.$v.$invalid) {
        this.$store.dispatch('brands/addBrand', this.brand)
        this.showDialog = false
        this.cleanUp()
      }
    },
    cleanUp() {
      this.$data.brand = this.$options.data.apply(this).brand
      this.$v.$reset()
    },
    onCancel () {
      this.showDialog = false
    }
  }
}
</script>
