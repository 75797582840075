export default {
  data() {
    return {
      searched: [],
      search: ''
    }
  },
  methods: {
    searchByName(items, term, criteria) {
      if(term !== undefined)
        return items.filter(item => item[criteria].toLowerCase().includes(term.toLowerCase()))
      return items
    },
    searchOnTable (param) {
      this.searched = this.searchByName(this[param], this.search)
    }
  }
}
