<template>
  <md-table-cell colspan="6">
    <div class="md-layout md-alignment-center">
      <div class="md-layout-item md-size-20">
        <br>
        <span>No hay registros</span>
      </div>
    </div>
  </md-table-cell>
</template>

<script>
export default {
  name: 'Empty'
}
</script>
