<template>
  <div class="md-layout md-alignment-center top">
    <div class="md-layout-item md-size-50">
      <md-progress-bar md-mode="indeterminate"></md-progress-bar> <br>
      <span>Cargando registros, favor espere</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>
